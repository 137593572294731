@import "styles/_variables.scss";
.rodape {
  &__info {
    background-color: white;
    color: $paragrafo;
    display: flex;
    min-height: 60vh;
    justify-content: space-around;
  }
  &__campo:first-child {
    width: 30vw;
    margin: 0 2rem;
    margin-top: 4rem;
  }
 
  &__campo {
    width: 25vw;
    margin-top: 4rem;
  }
  &__campo:nth-child(4) {
    margin-right: 3rem;
    width: 32vw;
  }
  .logo--div {
    padding: 0 2rem;
    line-height: 2rem;
  }
  &__ap {
    line-height: 1.4rem;
  }

  &__subtitulo {
    color: $titulo;
    font-size: 1.1rem;
  }
  &__lista {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
  &__logo {
    width: 70%;
    margin-bottom: 1rem;
    transition: 500ms;
    &:hover {
      opacity: 0.7;
    }
  }
  &__link {
    color: $paragrafo;
    text-decoration: none;
    transition: 500ms;
    &:hover {
      color: $paragrafo;
      opacity: 0.7;
      text-decoration: none;
    }
  }
  &__lista--item {
    margin: 1rem 0;
  }
}
@media (max-width: $medium) {
  .rodape {
    &__info {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0px;
      margin: 0 auto;
      width: calc(100vw - 3rem);
      flex-wrap: wrap;
    }
    &__campo {
      width: 40vw;
      padding: 0 2rem;
        &:first-child {
            width: 60vw;
            text-align: center;
        }
        &:nth-child(4) {
            justify-self: start;
        }
    }
  }
}
@media (max-width: $small) {
  .rodape {
    &__info {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: calc(100vw - 2rem);
      flex-wrap: wrap;
    }
    &__campo:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0px;

      margin: 0px;
    }
    &__campo:nth-child(4) {
        width: calc(90vw - 4rem);
        padding: 0px;
    }
    .logo--div {
      padding: 0px;
      margin: 0px;
      margin-top: 1rem;
    }
    &__campo {
      width: calc(100vw - 4rem);
      padding: 0px;
    }
    &__campo:first-child {
      width: calc(100vw - 2rem);
    }
  }
}

.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $cinza-claro;
  width: calc(100vw);
  margin: 0px auto;
  &__paragrafo--div {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 3rem;
    &:nth-child(2) {
      color: $azul;
    }
    &:nth-child(2) > a {
      text-decoration: none;
      color: $azul;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__idioma {
    display: flex;
    align-items: center;
  }
}
.select-idioma {
  height: 40px;
  font-size: 0.9rem;
  padding: 0.2rem;
  min-width: 200px;
  margin: 0 !important;
  padding-left: 0.5rem;
}

@media (max-width: $medium) {
  .logo--div {
    margin: 0px;
  }
  .copyright {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    background-color: $cinza-claro;
    width: calc(100vw - 3rem);
    margin: 0px auto;
    padding: 1.5rem;
    &__paragrafo--div {
      font-size: 0.9rem;
      display: flex;
      margin: 1rem 0;
      align-items: center;
      justify-content: center;
        &:nth-child(2) {
            margin-right: 0px;
        }
    }
    
    &__idioma {
      display: flex;
      align-items: center;
    }
  }
  .select-idioma {
    height: 40px;
    font-size: 0.9rem;
    padding: 0.2rem;
    min-width: 200px;
    margin: 0 !important;
    padding-left: 0.5rem;
  }
}
