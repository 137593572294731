$branco: #FFFFFF;
$text-shadow: rgba(0, 0, 0, 0.25);

$azul: #1666FF;
$azul-forte: #0B5ED7;

$titulo: #213344;
$paragrafo: #5D6C7C;

$cinza-claro: #F3F3F3;  

$small: 552px;
$medium: 768px;
$large: 992px;

