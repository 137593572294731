@import "styles/_variables.scss";
.contato {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 5vw;
  min-height: 90vh;
  z-index: -1;
  justify-content: center;
  &__apresentacao {
    margin-bottom: 3rem;
  }
  &__titulo {
    font-weight: bold;
    font-size: 2rem;
  }

  &__imagem {
    width: 100%;
  }
  &__apresentacao--imagem {
    display: block;
    max-width: 300px;
  }
  &__formulario {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    width: calc(100vw - 2rem);
  }
  &__input {
    border: 1px solid #ccc;
    color: $paragrafo;
    border-radius: 8px;
    font-family: "Poppins";
    height: 40px;
    padding: 0 1rem;
    margin: .3rem;
    font-size: 1rem;
    &:focus {
        outline: none;
    }
  }
  &__input--assunto {
    grid-column: span 2;
  }
  &__input--mensagem {
    grid-column: span 2;
    padding-top: .3rem;
    min-height: 100px;
  }
  &__botao {
    color: white;
    background-color: $azul;
    
    border: none;
    margin-top: .3rem;
    margin-left: .3rem;
    font-size: 1rem;
    transition: 500ms;
    height: 42px;
    font-family: "Poppins";
    border-radius: 8px;
    &:hover {
      background-color: $azul-forte;
      cursor: pointer;
    }
    &:disabled {
      opacity: .7;
      cursor: auto;
    }
  }
  &__mapa {
    margin: 2rem 0;
    width: calc(100vw);
  }
}

@media (min-width: $medium) {
    .contato {
        flex-direction: row;
        &__titulo {
            font-size: 2.5rem;
        }
        &__formulario {
            width: 100%;
        }
        &__imagem {
            width: 80%;
            margin: 0 auto;
            display: block;
        }
        &__apresentacao {
            width: 50%;
            margin: 0 .5rem;
        }
        &__apresentacao--imagem {
            width: 50%;
            max-width: none;
            margin: 0 .5rem;

        }
        &__mapa {
            width: calc(100vw - 1rem);
        }
    }

}