@import '../../styles/variables';

.cabecalho {
  width: calc(100vw);
  display: flex;
  height: 80px;
  z-index: 2;
  top: 0;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(15px) !important;

  &__contato {
    width: 100%;
  }
  &__logo {
    width: 160px;
    margin-left: 1rem;
    transition: 500ms;
    &:hover {
      opacity: 0.7;
    }
  }
  &__navegacao--div {
    position: fixed;
    z-index: 2;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: 500ms;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    background-color: $text-shadow;
    
  }
  &__navegacao--div--aparecer {
    opacity: 1;
    visibility: visible;
    
  }
  &__navegacao {
    position: fixed;
    transition: 500ms;
    z-index: 2;

    opacity: 0;
    visibility: hidden;
    transform: translate(-75vw);
    top: 0;
    color: $branco;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    left: 0;
    background-color: $azul;
    min-height: 100vh;
    width: 320px;
  }
  &__navegacao--aparecer {
    opacity: 1;
    visibility: visible;
    transform: translate(0);
  }
  &__menuicon {
    margin-right: 1rem;
    transform: scale(1.5);
    fill: $titulo;
    &:hover {
      cursor: pointer;
    }
  }
  &__closeicon {
    fill: $branco;
    opacity: 0.8;
    transform: scale(1.3);
    margin: 0.5rem;
    &:hover {
      cursor: pointer;
    }
  }
  &__lista {
    width: 90%;
    margin: 0 auto;
  }
  &__lista > a {
    text-decoration: none;
    color: $branco;
  }
  &__link {
    padding: 1rem;
    transition: 500ms;
    &:hover {
      cursor: pointer;
      background-color: $branco;
      color: $azul;
    }
  }
  &__link--selecionado {
    background-color: $branco;
    color: $azul;
  }
  &__contato--div {
    width: 100%;
    margin-top: 1rem;
  }
  &__contato--div--desk {
    display: none;
  }
  &__contato {
    display: block;
    margin: 0 auto;
    width: 90%;
    padding: .5rem; 
    border-radius: 5px;
    background-color: white;
    text-decoration: none;
    border: none;
    color: $titulo;
    font-family: "Poppins";
    transition: 500ms;
    &:hover {
      background-color: rgb(228, 228, 228);
      cursor: pointer;
    }
  }
  &__contato--div a {
    text-decoration: none;
  }
}
@media (min-width: $medium) {
  .cabecalho {
    display: flex;
    align-items: center;
    &__link--selecionado {
      background-color: transparent;
    }
    &__logo {
      margin-left: 3rem;
      margin-top: 1rem;
    }
    &__navegacao {
      visibility: visible;
      opacity: 1;
      max-width: 600px;
      display: inline;
      transition: 500ms;
      justify-content: flex-end;
      transform: translate(0);
      top: 0;
      color: $branco;
      display: flex;
      flex-direction: row;
      align-items: center;
      left: 0;
      background-color: transparent;
      min-height: 80px;
      width: 80vw;
      position: static;
    }
    &__navegacao--div {
      display: none;
    }
    &__navegacao > svg {
      display: none;
    }
    &__menuicon {
      display: none;
    }
    &__link {
      &:hover {
        background-color: transparent;
      }
    }
    
    &__lista {
      justify-content: center;
      display: flex;
      align-items: center;
    }
    &__lista > a {
      color: $titulo;
    }
    &__contato--div {
      display: block;
      width: 180px;
      display: flex;
      margin-right: 3rem;
    }
    &__contato--div--mob {
      display: none;
    }
    &__contato {
      width: 180px !important;
      background-color: $azul;
      color: white;
      font-size: 1rem;
      transform: translateY(-.2rem);

      &:hover {
        background-color:$azul;
        transform: translateY(-.5rem);
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.11);
      }
    }

  }
}
