@import 'styles/_variables.scss';
.newsletter {
    width: calc(100vw);
    min-height: 60vh;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    background-image: url('../../assets/img/newsletter-fundo.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    &__titulo {
        font-weight: bold;
        font-size: 2rem;
        margin: 1rem;
        line-height: 2.4rem;
    }
    &__paragrafo {
        margin: 1rem;
        line-height: 1.3rem;
    }
    &__flex {
        margin: 1rem;
        display: flex;
        justify-content: space-between;
    }
    &__input {
        padding: .5rem 1rem; 
        border: none ;
        font-size: 1rem;
        border-radius: 5px;
        width: 50%;
        font-family: "Poppins";
        &:focus {
            outline: none;
        }
    }
    &__botao {
        color: white;
        background-color: $azul;
        border: none;
        width: 40%;
        font-size: 1rem;
        padding: .5rem 1rem;
        transition: 500ms;
        font-family: "Poppins";
        border-radius: 5px;
        &:hover {
            background-color: $azul-forte;
            cursor: pointer;
        }
        &:disabled {
            opacity: .7;
            cursor: auto;
        }
    }
}
@media (min-width: $medium) {
    .newsletter {
        width: 92vw;
        border-radius: 20px;
        margin: 3rem auto;
        &__input {
            width: 55%;
        }
        &__botao {
            width: 40%;
            margin-left: .3rem;
        }
        &__flex {
            width: 100%;
        }
    }
    .newsletter > div {
        padding: 2rem;
        width: 50%;
    }
}