@import "../../styles/variables";
.inicio {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 3rem 5vw;
  min-height: 90vh;
  z-index: -1;
  background-image: url("../../assets/img/fundo-inicio-mob.png");
  background-position: 3vw 5rem;
  background-size: 550px;
  background-repeat: no-repeat;
  &__apresentacao {
    margin-bottom: 3rem;
  }
  &__titulo {
    font-weight: bold;
    font-size: 2rem;
  }
  &__titulo--div {
    text-align: left;
    min-height: 70px;
  }
  &__paragrafo {
    line-height: 1.5rem;
    color: $paragrafo;
  }
  &__imagem {
    width: 100%;
  }
  &__apresentacao--imagem {
    display: none;
  }
  &__checks {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    &:nth-child(2) {
      margin-left: 1rem;
    }
  }
  &__botao {
    color: white;
    background-color: $azul;
    border: none;
    margin: 0.5rem 0;
    font-size: 1rem;
    padding: 0.8rem 2rem;
    transition: 500ms;
    font-family: "Poppins";
    border-radius: 5px;
    &:hover {
      background-color: $azul-forte;
      cursor: pointer;
    }
  }
  &__checks > p {
    margin-left: 0.5rem;
    font-size: 0.8rem;
  }
  &__arrowdown {
    margin-left: 2rem;
    transition: 500ms;
    animation: moverParaBaixo 2s linear infinite;
    @keyframes moverParaBaixo {
      0% {
        transform: translateY(0);
        opacity: 0;
      }
      50% {
        opacity: 1;
      }

      100% {
        transform: translateY(40px);
        opacity: 0;
      }
    }
  }
}

.empresas {
  width: 90vw;
  margin: 0 auto;
  min-height: 60vh;
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__titulo {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__paragrafo {
    margin: 0.5rem 0;
    text-align: center;
  }
  &__lista {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__empresa > img {
    width: 80%;
  }
}

.projetos {
  width: 90vw;
  margin: 0 auto;
  min-height: 60vh;
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__titulo {
    font-weight: bold;
    font-size: 6rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__paragrafo {
    margin: 0.5rem 0;
    text-align: center;
    width: 90%;
    font-size: 1rem;
    line-height: 1.4rem;
  }
 
}

.publicidade {
  width: 90vw;
  margin: 0 auto;
  margin-top: 2rem;
  &__subtitulo {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
  &__paragrafo {
    line-height: 1.5rem;
  }
  &__fotos {
    display: none;
  }
  &__lista {
    margin-top: 2rem;
  }
}

.depoimentos {
  min-height: 100vh;
  background-color: $cinza-claro;
  padding: 1rem;
  margin-top: 5rem;
  &__titulo {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-top: 3rem;
  }
  &__subtitulo {
    color: $paragrafo;
    margin: 1rem 0;
    margin-bottom: 2rem;
    text-align: center;
  }
  &__paragrafo {
    color: $paragrafo;
    margin: 1rem 0;
    font-weight: bold;
    line-height: 1.5rem;
  }
  &__lista {
    padding: 0px;
    margin: 0px;
    width: calc(100vw - 2rem);
  }
  &__depoimento {
    background-color: $branco;
    border-radius: 20px;
    border: 1px solid rgb(187, 187, 187);
    padding: 1.5rem;
    margin-bottom: 1rem;
  }
  &__foto {
    width: 15%;
    border-radius: 50%;
    max-width: 50px;
  }
  &__resp {
    display: flex;
    align-items: center;
  }
  &__in {
    margin-left: 1rem;
  }
  &__nome {
    font-weight: bold;
    margin-bottom: 0.3rem;
  }
}

@media (min-width: $medium) {
  .inicio {
    flex-direction: row;
    background-image: none;
    min-height: 100vh;
    transform: translateY(2rem);
    &__arrowdown {
      margin-left: 4rem;
      margin-top: -5rem;
    }
    &__imagem {
      width: 100%;
    }
    &__titulo {
      line-height: 2.9rem;
      font-size: 2.5rem;
    }
    &__titulo--div {
      min-height: 200px;
    }
    &__apresentacao {
      width: 40%;
    }
    &__apresentacao--imagem {
      width: 60%;
      display: block;
    }
  }
  .publicidade {
    min-height: 100vh;
    display: flex;
    align-items: center;
    &__info {
      width: 50%;
    }
    &__fotos {
      width: 50%;
      max-width: 500px;
      margin: 0 auto;
      display: grid;
      gap: 1.5rem;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
    }
    &__fotos > img {
      width: 100%;
      margin: 0 0.5rem;
    }
  }
  .depoimentos {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 70vh;
    justify-content: center;
    &__lista {
      display: flex;
      width: calc(100vw - 1rem);
    }
    &__titulo {
      font-size: 3rem;
    }
    &__depoimento {
      margin: 0 0.5rem;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      width: 33%;
    }
  }
  .empresas {
    &__titulo {
      font-size: 3rem;
    }
  }
}
@media (min-width: $large) {
  .inicio {
    &__imagem {
      width: 100%;
    }
    &__titulo {
      line-height: 3.8rem;
      font-size: 3.5rem;
    }
    &__titulo--div {
      min-height: 150px;
    }
    &__apresentacao {
      width: 50%;
    }
    &__apresentacao--imagem {
      width: 50%;
    }
    &__checks > p {
      font-size: 0.9rem;
    }
  }
  .depoimentos {
    &__lista {
      display: flex;
      width: 80vw;
    }
  }
  .projetos {
    &__titulo {
      font-size: 8rem;
    }
    &__paragrafo {
      font-size: 1.2rem;
      line-height: 1.7rem;
      width: 60%;
    }
  }
}
