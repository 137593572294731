@import "../../styles/variables";
.empresa {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 3rem 5vw;
  min-height: 90vh;
  z-index: -1;
  background-image: url("../../assets/img/fundo-empresa.png");
  background-position: -20rem 2rem;
  background-size: 700px;
  background-repeat: no-repeat;
  &__apresentacao {
    margin-bottom: 3rem;
  }
  &__titulo {
    font-weight: bold;
    font-size: 2rem;
  }
  &__titulo--div {
    text-align: left;
    min-height: 110px;
  }
  &__paragrafo {
    line-height: 1.5rem;
    color: $paragrafo;
  }
  &__imagem {
    width: 100%;
  }
  &__apresentacao--imagem {
    display: none;
  }

  &__checks > p {
    margin-left: 0.5rem;
    font-size: 0.8rem;
  }
}

.sobre {
  min-height: 60vh;
  padding: 1rem;
  &__titulo {
    font-size: 2rem;
    font-weight: bold;
    margin: 1rem 0;
  }
  &__paragrafo {
    margin: 0.5rem 0;
    line-height: 1.5rem;
    color: $paragrafo;
  }
  &__paragrafo:nth-child(2) {
    margin-bottom: 1rem;
  }
}

.objetivos {
  margin: 3rem 0;
  padding: 2rem 1rem;
  background-image: linear-gradient(
    90deg,
    rgba(125, 169, 255, 0.2),
    rgba(32, 87, 194, 0.2)
  );
  &__subtitulo {
    font-weight: bold;
    margin: 1rem 0;
    font-size: 1.5rem;
  }
  &__paragrafo {
    line-height: 1.5rem;
    color: $paragrafo;
  }
}

@media (min-width: $medium) {
  .empresa {
    flex-direction: row-reverse;
    background-image: url("../../assets/img/fundo-empresa-desktop.png");
    min-height: 100vh;
    background-position: -35vw -4rem;
    background-size: 750px;
    transform: translateY(2rem);
    &__arrowdown {
      margin-left: 4rem;
      margin-top: -5rem;
    }
    &__imagem {
      width: 500px;
      margin-top: -5rem;
      margin-left: -6rem;
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
    }
    &__titulo {
      line-height: 2.9rem;
      font-size: 2.5rem;
    }
    &__titulo--div {
      min-height: 200px;
    }
    &__apresentacao {
      width: 40%;
    }
    &__apresentacao--imagem {
      width: 40%;
      display: block;
      margin: 0 auto;
    }
  }
  .sobre {
    width: 70vw;
    margin: 0 auto;
    min-height: 50vh;
  }
  .objetivos {
    padding: 5rem 0;
    &__lista {
        display: flex;
        align-items: start;
        justify-content: space-around;
    }
    &__item {
        margin: 0 1rem;
        width: 33%;
    }
  }
}

@media (min-width: $large) {
  .empresa {
    background-position: -20vw -4rem;
    &__imagem {
      width: 600px;
      margin-left: -10rem;
    }
    &__titulo {
      line-height: 3.8rem;
      font-size: 3.5rem;
    }
    &__titulo--div {
      min-height: 250px;
    }
    &__apresentacao {
      width: 40%;
    }
    &__apresentacao--imagem {
      width: 40%;
    }
    &__checks > p {
      font-size: 0.9rem;
    }
  }
  .objetivos {
    &__item {
        margin: 0 2rem;
        width: 33%;
    }
  }
}
