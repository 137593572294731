@import "../../styles/variables";
.servicos {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 3rem 5vw;
  min-height: 90vh;
  z-index: -1;
  background-image: url("../../assets/img/fundo-servicos.png");
  background-position: 10vw -2rem;
  background-size: 900px;
  background-repeat: no-repeat;
  &__apresentacao {
    margin-bottom: 3rem;
 
  }
  &__titulo {
    font-weight: bold;
    font-size: 2rem;
  }
  &__titulo--div {
    text-align: left;
    min-height: 70px;
  }
  &__paragrafo {
    line-height: 1.5rem;
    color: $paragrafo;
  }
  &__imagem {
    width: 100%;
  }
  &__apresentacao--imagem {
    display: none;
  }

  &__checks > p {
    margin-left: 0.5rem;
    font-size: 0.8rem;
  }
}
.ferramentas {
    margin: 3rem 0;
    &__artigo {
        padding: 1rem 2rem;
        border-radius: 10px;
        min-height: 200px;
        margin: 1rem;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    }
    &__titulo {
        font-size: 2rem;
        text-align: center;
        margin-bottom: 2rem;
    }
    &__subtitulo {
        font-weight: bold;
        margin: 1rem 0;
    }
    &__paragrafo {
        color: $paragrafo;
    }
}
.produtos {
    margin: 3rem 0;
   
    &__titulo {
        font-size: 2rem;
        text-align: center;
        margin-bottom: 2rem;
    }
 
}
.entrecontato {
    min-height: 20vh;
    display: flex;
    width: 90vw;
    margin: 4rem auto;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    &__titulo {
        font-size: 2rem;
    }
    &__botao {
        color: white;
        background-color: $azul;
        border: none;
        margin: 1rem 0 0 0;
        font-size: 1rem;
        padding: .8rem 2rem;
        width: 90vw;
        transition: 500ms;
        font-family: "Poppins";
        border-radius: 5px;
        &:hover {
            background-color: $azul-forte;
            cursor: pointer;
        }
    }
}
@media (min-width: $medium) {
  .servicos {
    flex-direction: row;
    background-image: url('../../assets/img/fundo-servicos-desktop.png');
    min-height: 100vh;
    background-position: 50vw -1rem;
    background-size: 650px;
    transform: translateY(2rem);
    &__arrowdown {
      margin-left: 4rem;
      margin-top: -5rem;
    }
    &__imagem {
      width: 100%;
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));

    }
    &__titulo {
      line-height: 2.9rem;
      font-size: 2.5rem;
    }
    &__titulo--div {
      min-height: 200px;
    }
    &__apresentacao {
      width: 40%;
    }
    &__apresentacao--imagem {
      width: 40%;
      display: block;
        margin: 0 auto;
    }
  }
  .ferramentas {
    &__titulo {
      font-size: 3rem;
    }
    &__lista {
        width: 80vw;
        margin: 0 auto;
    }
  }
  .produtos {
    &__titulo {
      margin-top: 8rem ;

      font-size: 3rem;
    }
 
  }
  .entrecontato {
    flex-direction: row;
    &__botao {
        width: 300px;
    }
  }
}
@media (min-width: $large) {
    .servicos {
        
        &__imagem {
            width: 100%;
        }
        &__titulo {
            line-height: 3.8rem;
            font-size: 3.5rem;
        }
        &__titulo--div {
            min-height: 250px;
        }
        &__apresentacao {
            width: 40%;
        }
        &__apresentacao--imagem {
            width: 40%;
        }
        &__checks > p {
            font-size: .9rem;
        }
    }
}

.esquerda {
  min-height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  &__div {
    margin: 1rem 0;
  }
  &__imagem {
    margin: 0 auto;
    display: block;
    width: 80%;
  }
  &__titulo {
    font-size: 2rem;
    margin: 1rem 0;
  }
  &__paragrafo {
    line-height: 1.5rem;
    color: $paragrafo;
  }
  &__botao {
    color: white;
    background-color: $azul;
    border: none;
    margin: 0.5rem 0;
    font-size: 1rem;
    padding: 0.8rem 2rem;
    transition: 500ms;
    font-family: "Poppins";
    border-radius: 5px;
    &:hover {
      background-color: $azul-forte;
      cursor: pointer;
    }
  }
}

@media (min-width: $medium) {
  .esquerda {
    display: flex;
    flex-direction: row;

    &__div {
      width: 50%;
      &:first-child {
        width: 40%;
      }
    }
    &__imagem {
      max-width: 350px;
    }
  }
  .direita {
    display: flex;
    flex-direction: row-reverse;
    &__div {
      width: 50%;
      &:first-child {
        width: 40%;
      }
    }
    &__imagem {
      max-width: 350px;
    }
  }
}