@import "../../styles/variables";
.casosdesucesso {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 3rem 5vw;
  min-height: 90vh;
  z-index: -1;
  background-image: url("../../assets/img/fundo-casosdesucesso.png");
  background-position: 10vw -1rem;
  background-size: 800px;
  background-repeat: no-repeat;
  &__apresentacao {
    margin-bottom: 3rem;
  }
  &__titulo {
    font-weight: bold;
    font-size: 2rem;
  }
  &__titulo--div {
    text-align: left;
    min-height: 70px;
  }
  &__paragrafo {
    line-height: 1.5rem;
    color: $paragrafo;
  }
  &__imagem {
    width: 100%;
  }
  &__apresentacao--imagem {
    display: none;
  }

  &__checks > p {
    margin-left: 0.5rem;
    font-size: 0.8rem;
  }
}

.esquerda {
  min-height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  &__div {
    margin: 1rem 0;
  }
  &__imagem {
    margin: 0 auto;
    display: block;
    width: 80%;
  }
  &__titulo {
    font-size: 2rem;
    margin: 1rem 0;
  }
  &__paragrafo {
    line-height: 1.5rem;
    color: $paragrafo;
  }
  &__botao {
    color: white;
    background-color: $azul;
    border: none;
    margin: 0.5rem 0;
    font-size: 1rem;
    padding: 0.8rem 2rem;
    transition: 500ms;
    font-family: "Poppins";
    border-radius: 5px;
    &:hover {
      background-color: $azul-forte;
      cursor: pointer;
    }
  }
}

@media (min-width: $medium) {
  .casosdesucesso {
    flex-direction: row-reverse;
    background-image: url("../../assets/img/fundo-casosdesucesso-desktop.png");
    min-height: 100vh;
    background-position: -20vw -1rem;
    background-size: 650px;
    transform: translateY(2rem);
    &__arrowdown {
      margin-left: 4rem;
      margin-top: -5rem;
    }
    &__imagem {
      width: 500px;
      margin-top: -5rem;
      margin-left: -6rem;
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
    }
    &__titulo {
      line-height: 2.9rem;
      font-size: 2.5rem;
    }
    &__titulo--div {
      min-height: 200px;
    }
    &__apresentacao {
      width: 40%;
    }
    &__apresentacao--imagem {
      width: 40%;
      display: block;
      margin: 0 auto;
    }
  }

  .esquerda {
    display: flex;
    flex-direction: row;

    &__div {
      width: 50%;
      &:first-child {
        width: 40%;
      }
    }
    &__imagem {
      max-width: 350px;
    }
  }
  .direita {
    display: flex;
    flex-direction: row-reverse;
    &__div {
      width: 50%;
      &:first-child {
        width: 40%;
      }
    }
    &__imagem {
      max-width: 350px;
    }
  }
}
@media (min-width: $large) {
  .casosdesucesso {
    background-position: -5vw -1rem;

    &__imagem {
      width: 550px;
    }
    &__titulo {
      line-height: 3.8rem;
      font-size: 3.5rem;
    }
    &__titulo--div {
      min-height: 200px;
    }
    &__apresentacao {
      width: 50%;
    }
    &__apresentacao--imagem {
      width: 40%;
    }
    &__checks > p {
      font-size: 0.9rem;
    }
  }
}
